import CHECKMARK_CIRCLE_OUTLINED from "../../assets/icons/checkmark_circle.svg";
import MAIL_TO_US_MOBILE_IMAGE from "../../assets/images/talk_to_us_image_mobile.png";
import MAIL_TO_US from "../../assets/images/mailUsImage.png";
import learningActivityImage from "../../assets/images/learningActivity_image.png";
import { Button, Image, Carousel } from "antd";
import image1 from "../../assets/images/course_image_1.webp";
import image2 from "../../assets/images/course_image_2.webp";
import image4 from "../../assets/images/course_image_4.webp";
import "./landingPage.scss";
import ImageWithCancel from "../../component-library/imageWithCancel/ImageWithCancel";
import {
  middleSubText,
  middleText,
  subUpperText,
  textArray,
  upperTextOne,
  upperTextTwo,
} from "./landingPageConfigs";

const cookiePolicy =
  "https://storage.googleapis.com/rlms-public-assets/RLMS%20Cookie%20Policy%20(2024.09.25%20Final)%5B69%5D.pdf";
const privacyNote =
  "https://storage.googleapis.com/rlms-public-assets/RLMS%20Privacy%20Notice%20(2024.09.25%20Draft)%5B12%5D.pdf";
const securityOverview =
  "https://storage.googleapis.com/rlms-public-assets/RLMS%20Security%20Overview%20(2024.09.11%20Final)%5B23%5D.pdf";
const dataProcessingURL =
  "https://storage.googleapis.com/rlms-public-assets/RMLS%20Data%20Processing%20Addendum%20(2024.09.25%20Draft)%5B88%5D.pdf";
const saasUrl =
  "https://storage.googleapis.com/rlms-public-assets/R-LMS%20-%20SAAS%20Agreement%20v2.0.pdf";
const ossUrl =
  "https://storage.googleapis.com/rlms-public-assets/OSS%20R-LMS.pdf";

function LandingPage() {
  const isMobile = window.innerWidth < 426;
  const endUserBenefitsImages = [image4, image1, image2];
  const lodTeamsBenefitsImages = [image4, image1, image2];

  const renderHeader = () => {
    return (
      <div
        className="landing-page-header"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="landing-page-header-growlogo-container-1">
          <ImageWithCancel
            imageUrl="https://storage.googleapis.com/rlms-public-assets/RLMS_LANDING_PAGE_LOGO.svg"
            placeholderUrl="https://storage.googleapis.com/rlms-public-assets/RLMS_LANDING_PAGE_LOGO_1kb.png"
            className="landing-page-header-growlogo"
            height={48}
          />
        </div>
        {!isMobile && (
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImageWithCancel
              imageUrl="https://storage.googleapis.com/rlms-public-assets/RAKUTEN_LOGO.svg"
              placeholderUrl="https://storage.googleapis.com/rlms-public-assets/RAKUTEN_LOGO_1kb.png"
              styles={{ height: "2.5rem", width: "10.78rem" }}
            />
          </div>
        )}
        <div className="landing-page-header-growlogo-container-2">
          <Button
            className="landing-page-header-signin-button"
            onClick={() => {
              window.location.href =
                process.env.REACT_APP_AUTH_SERVICE_URL +
                "/organizations/select?redirectUrl=" +
                window.location;
            }}
          >
            SIGN IN
          </Button>
        </div>
      </div>
    );
  };

  const renderTopGraphicon = () => {
    return (
      <div className="landing-page-top-graph-icon">
        {isMobile ? (
          <Image
            src="https://storage.googleapis.com/rlms-public-assets/reading%20first%20page.svg"
            alt="Learning-activity"
            width={isMobile ? "60%" : "100%"}
            preview={false}
            loading="lazy"
          />
        ) : (
          <ImageWithCancel
            imageUrl="https://storage.googleapis.com/rlms-public-assets/reading%20first%20page.svg"
            placeholderUrl="https://storage.googleapis.com/rlms-public-assets/reading%20first%20page_1kb.png"
            // className="landing-page-header-growlogo"
            height={134}
            styles={{ width: "100%" }}
          />
        )}
      </div>
    );
  };

  const renderText = (text, classname) => {
    return <span className={classname}>{text}</span>;
  };

  const renderDots = () => {
    return (
      <ImageWithCancel
        imageUrl="https://storage.googleapis.com/rlms-public-assets/PROMOTIONAL_PAGE_DOTS.svg"
        styles={{ height: isMobile ? 12 : 32 }}
        className="pg-dots"
      />
    );
  };

  const renderBannerImage = () => {
    return (
      <div className="landing-page-banner-image-container">
        <ImageWithCancel
          imageUrl="https://storage.googleapis.com/rlms-public-assets/homepage_group_image.webp"
          placeholderUrl="https://storage.googleapis.com/rlms-public-assets/homepage_group_image_1kb.webp"
          className="promotional-page-banner-image"
        />

        {isMobile ? (
          <>
            {/* <img
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              src={VIDEO_PLAY_ICON}
              alt="video-play-icon"
              loading="lazy"
            />
            <span
              style={{
                position: "absolute",
                fontFamily: "RakutenSansSemiBold",
                fontSize: "14px",
                color: "white",
                left: "8%",
                bottom: "5%",
                width: "70%",
              }}
            >
              {imageText}
            </span> */}
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: "20%",
              left: "10%",
              display: "flex",
            }}
          >
            {/* <img
              width={49}
              height={49}
              style={{
                marginRight: "1rem",
              }}
              src={VIDEO_PLAY_ICON}
              alt="video-play-icon"
              loading="lazy"
            />
            <span
              style={{
                fontFamily: "RakutenSansSemiBold",
                fontSize: "20px",
                color: "white",
                lineHeight: "25px",
                width: "55%",
              }}
            >
              {imageText}
            </span> */}
          </div>
        )}
      </div>
    );
  };

  const CustomCarousel = ({ images, autoplay = true, style = {} }) => {
    return (
      <Carousel
        autoplay={autoplay}
        className="custom-carousel"
        arrows={false}
        style={{
          ...style,
        }}
      >
        {images.map((image, index) => (
          <div key={index}>
            <ImageWithCancel
              imageUrl={image}
              styles={{
                width: "100%",
                height: "auto",
                maxHeight: "374px",
                objectFit: "cover",
              }}
            />
          </div>
        ))}
      </Carousel>
    );
  };

  const renderUserBenefits = () => {
    return (
      <div className="landing-page-end-user-benefits-container">
        <div className="landing-page-end-user-benefits-text">
          <span
            style={{
              fontFamily: "RakutenSansSemiBold",
              color: "#6C00A5",
              fontSize: 24,
              marginLeft: "24px",
            }}
          >
            End User Benefits
          </span>
          <ul>
            <li style={{ marginBottom: 8, marginTop: "1rem" }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Flexible Learning:
              </span>{" "}
              Access courses at their own pace.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Skill Development:
              </span>{" "}
              Stay current with the latest technologies and methodologies.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Engaging Content:
              </span>{" "}
              Interactive and engaging courses to enhance retention.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Career Growth:
              </span>{" "}
              Clear learning paths for career advancement
            </li>
          </ul>
        </div>
        {isMobile ? (
          <div
            style={{
              width: "243px",
              height: "203px",
              marginTop: "52px",
              marginBottom: "96px",
            }}
          >
            <CustomCarousel images={endUserBenefitsImages} />
          </div>
        ) : (
          <div
            style={{
              marginLeft: "100px",
              width: "493px",
              height: "466px",
              borderRadius: "8px",
              backgroundColor: "#FCF6FF",
            }}
          >
            <CustomCarousel
              style={{
                marginTop: "32px",
                marginRight: "32px",
                marginLeft: "32px",
              }}
              images={endUserBenefitsImages}
            />
          </div>
        )}
      </div>
    );
  };

  const renderLodTeamBenefits = () => {
    return (
      <div className="landing-page-lod-benefits-container">
        {isMobile ? (
          <div
            style={{
              width: "243px",
              height: "203px",
              order: 2,
              marginTop: "52px",
              marginBottom: "60px",
            }}
          >
            <CustomCarousel images={endUserBenefitsImages} />
          </div>
        ) : (
          <div
            style={{
              marginRight: "100px",
              width: "493px",
              height: "466px",
              borderRadius: "8px",
              backgroundColor: "#FCF6FF",
            }}
          >
            <CustomCarousel
              style={{
                marginTop: "32px",
                marginRight: "32px",
                marginLeft: "32px",
              }}
              images={lodTeamsBenefitsImages}
            />
          </div>
        )}
        <div className="landing-page-lod-team-benefits-text">
          <span
            style={{
              fontFamily: "RakutenSansSemiBold",
              color: "#6C00A5",
              fontSize: 24,
              marginLeft: "24px",
            }}
          >
            L&OD Team Benefits
          </span>
          <ul>
            <li style={{ marginBottom: 8, marginTop: "1rem" }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Increased Productivity:
              </span>{" "}
              Well-trained employees are more efficient.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Cost-Effective:
              </span>{" "}
              Reduce training costs with a scalable SaaS solution.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Data-Driven Decisions:
              </span>{" "}
              Use analytics to make informed training investments.
            </li>
            <li style={{ marginBottom: 8 }}>
              <span style={{ fontFamily: "RakutenSansSemiBold" }}>
                Compliance:
              </span>{" "}
              Ensure all employees meet mandatory training requirements.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderEndSection = () => {
    return (
      <div className="landing-page-end-section-container">
        <span className="landing-page-end-section-container-firstText">
          One-Stop Shop for Your Learning Excellence
        </span>
        <span className="landing-page-end-section-container-secondText">
          Empower your L&OD team with comprehensive courses, advanced analytics,
          and dedicated support. Drive growth and success today!
        </span>

        <div className="landing-page-talk-to-us-container">
          <img
            className="landing-page-talk-to-us-container-image"
            alt="mailImage"
            src={isMobile ? MAIL_TO_US_MOBILE_IMAGE : MAIL_TO_US}
            loading="lazy"
          />
          <div className="landing-page-talk-to-us-text-container">
            <div className="landing-page-talk-to-us-container-text">
              <span
                style={{
                  lineHeight: isMobile ? "30px" : "50px",
                  fontSize: isMobile ? 24 : 40,
                  fontFamily: "RakutenSansRegular",
                }}
              >
                Ready to get started?
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  lineHeight: isMobile ? "30px" : "50px",
                  fontSize: isMobile ? 24 : 40,
                  fontFamily: "RakutenSansSemiBold",
                }}
              >
                Talk to us today
              </span>
            </div>
            <div className="landing-page-button-container">
              <Button
                style={{
                  borderRadius: "8px",
                  background: "white",
                  color: "#6C00A5",
                  borderColor: "#6C00A5",
                  width: isMobile ? "9.063rem" : "12.438rem",
                  height: isMobile ? "2.125rem" : "3rem",
                  fontSize: 16,
                  fontFamily: "RakutenSansSemiBold",
                }}
              >
                Mail Us
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {isMobile && (
          <div className="landing-page-rakuten-logo">
            <img
              src={
                "https://storage.googleapis.com/rlms-public-assets/RAKUTEN_LOGO.svg"
              }
              height={48}
              loading="lazy"
            />
          </div>
        )}
        <div className="landing-page-footer">
          <div className="landing-page-footer-container-1">
            {!isMobile && (
              <img
                src={
                  "https://storage.googleapis.com/rlms-public-assets/RAKUTEN_LOGO.svg"
                }
                height={48}
                loading="lazy"
              />
            )}
            <span
              style={{
                width: isMobile ? "100%" : "",
                color: "#6C00A5",
                fontFamily: "RakutenSansSemiBold",
              }}
            >
              Rakuten India Enterprise Private Limited,
            </span>
            <span
              style={{
                width: isMobile ? "100%" : "",
                color: "#6C00A5",
                fontFamily: "RakutenSansRegular",
              }}
            >
              Bagamane Pallavi Tower #20, 1st Cross, S.R Nagar,
            </span>
            <span
              style={{
                width: isMobile ? "100%" : "",
                color: "#6C00A5",
                fontFamily: "RakutenSansRegular",
              }}
            >
              Raja Ram Mohan Roy Road, Bangalore - 560 027.
            </span>
            <span
              style={{
                width: isMobile ? "100%" : "",
                marginTop: isMobile ? "2.625rem" : "2rem",
                color: "#6C00A5",
                fontFamily: "RakutenSansRegular",
              }}
            >
              {isMobile
                ? "© Rakuten All rights reserved."
                : "© Rakuten India Enterprise Private Limited. All rights reserved."}
            </span>
          </div>
          <div className="landing-page-footer-container-2">
            {/* <div className="landing-page-footer-icon-container">
              <InstagramOutlined
                style={{ color: "#6C00A5", fontSize: 24, marginRight: 16 }}
              />
              <LinkedinOutlined style={{ color: "#6C00A5", fontSize: 24 }} />
            </div>
             */}
            <div style={{ fontSize: 14, marginTop: 16, color: "#6C00A5" }}>
              <span>
                <a
                  href={cookiePolicy}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  COOKIE POLICY
                </a>
              </span>
              <span style={{ margin: "0 16px" }}>|</span>
              <span style={{ cursor: "pointer" }}>
                <a
                  href={privacyNote}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  PRIVACY NOTE
                </a>
              </span>
              <span style={{ margin: "0 16px" }}>|</span>
              <span style={{ cursor: "pointer" }}>
                <a
                  href={securityOverview}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  SECURITY OVERVIEW
                </a>
              </span>
              <span style={{ margin: "0 16px" }}>|</span>
              <br></br>
              <span>
                <a
                  href={dataProcessingURL}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  DATA PROCESSING ADDENDUM
                </a>
              </span>
              <span style={{ margin: "0 4px" }}>|</span>
              <span>
                <a
                  href={saasUrl}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  SOFTWARE AS A SERVICE AGREEMENT
                </a>
              </span>
              <span style={{ margin: "0 4px" }}>|</span>
              <br></br>
              <span>
                <a
                  href={ossUrl}
                  target="_blank"
                  style={{ cursor: "pointer", color: "#6C00A5" }}
                  rel="noreferrer"
                >
                  OPEN SOURCE & 3rd PARTY LICENSES
                </a>
              </span>
            </div>
            <div className="landing-page-footer-mail">
              <span
                style={{
                  color: "#6C00A5",
                  fontFamily: "RakutenSansRegular",
                  fontSize: 14,
                }}
              >
                support-LMS-India@mail.rakuten.com
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLearningActivityImage = () => {
    return (
      <div
        className="landing-page-learning-activity-image-container"
        style={{ backgroundColor: "#FCF6FF" }}
      >
        {isMobile ? (
          <Image
            height={isMobile ? 221 : 603}
            loading="lazy"
            src={
              isMobile
                ? learningActivityImage
                : "https://storage.googleapis.com/rlms-public-assets/learning%20activity.png"
            }
            alt="Learning-activity"
            width={isMobile ? "207px" : "1133px"}
            preview={false}
          />
        ) : (
          <ImageWithCancel
            imageUrl="https://storage.googleapis.com/rlms-public-assets/learning%20activity.png"
            placeholderUrl="https://storage.googleapis.com/rlms-public-assets/learning%20activity_1kb.png"
            height={603}
            styles={{ width: "1133px" }}
          />
        )}
      </div>
    );
  };

  const renderLearningActivityText = () => (
    <div
      style={{
        backgroundColor: "#FCF6FF",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {textArray.map((item, index) => (
        <div
          key={index}
          style={{
            marginBottom: "1rem",
            width: "100%",
            backgroundColor: "white",
            padding: 16,
            display: "flex",
            flexDirection: "row",
            marginRight: "10px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <img
              src={CHECKMARK_CIRCLE_OUTLINED}
              height={17}
              alt="checkmarkcircle"
              loading="lazy"
            />
          </div>
          <div>
            <div
              style={{
                color: "#620F9F",
                fontWeight: "bold",
                fontFamily: "RakutenSansSemiBold",
                fontSize: "1.125rem",
                lineHeight: "1.42rem",
                marginBottom: "0.5rem",
              }}
            >
              {item.headlineText}
            </div>
            <div
              style={{
                color: "#00000080",
                fontFamily: "RakutenSansRegular",
                fontSize: "0.875rem",
                lineHeight: "1.104rem",
              }}
            >
              {item.normalText}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className="landing-page-container">
        {renderHeader()}
        {renderTopGraphicon()}
        {renderText(subUpperText, "sub-upper-text")}
        {renderText(upperTextOne, "upper-text")}
        {renderText(upperTextTwo, "upper-text")}
        {renderDots()}
      </div>
      {renderBannerImage()}
      {renderText(middleText, "middle-text")}
      {renderText(middleSubText, "middle-sub-text")}
      {renderLearningActivityImage()}
      {isMobile && renderLearningActivityText()}
      {renderUserBenefits()}
      {renderLodTeamBenefits()}
      {renderEndSection()}
      {renderFooter()}
    </>
  );
}

export default LandingPage;
